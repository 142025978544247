import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import MEDIA from "../helpers/mediaTemplates"

import Adaptile from "../svg/adaptile"

const FooterRoot = styled.footer`
  min-height: 48px;
`

const Background = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: 80% 80% !important;
  background-size: cover;
  ${MEDIA.TABLET`
    flex-direction: column;
`};
`

const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #ceced2;
  padding: 26px;
  span {
    opacity: 0.7;
  }
  a {
    padding-left: 10px;
    opacity: 0.7;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }

  ${MEDIA.TABLET`
    padding: 6px;
    &:first-of-type {
      padding-top: 20px;
    }
    &:last-of-type {
      padding-bottom: 20px;
    }
`};
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footerBackground: file(relativePath: { eq: "footerBackground.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <FooterRoot>
      <Background fluid={data.footerBackground.childImageSharp.fluid}>
        <Text>
          <span>
            © {new Date().getFullYear()} EdTech Founders. All rights reserved.
          </span>
        </Text>
        <Text>
          <span>Landing by </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://linkedin.com/company/adaptile"
          >
            <Adaptile width={70} />
          </a>
        </Text>
      </Background>
    </FooterRoot>
  )
}

export default Footer
