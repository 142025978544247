import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Helmet from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"

import Footer from "../components/footer"
// import Header from "./header"

const LayoutRoot = styled.div``

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <LayoutRoot>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main>{children}</main>
      <Footer />
    </LayoutRoot>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
