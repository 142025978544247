import React from "react"
import PropTypes from "prop-types"

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  viewBox = "0 0 62 13",
}) => (
  <svg
    width={width}
    style={style}
    height="100%"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <g transform="translate(-300 -299.984)">
      <path
        d="M0.3,-0.708L0.409,-0.708L0.714,0L0.57,0L0.504,-0.162L0.197,-0.162L0.133,0L-0.008,0L0.3,-0.708ZM0.46,-0.27L0.351,-0.558L0.24,-0.27L0.46,-0.27Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(316.555 310.034) scale(7.45348)"
      />
      <path
        d="M0.078,-0.708L0.329,-0.708C0.375,-0.708 0.421,-0.702 0.468,-0.69C0.515,-0.678 0.557,-0.658 0.594,-0.631C0.631,-0.603 0.662,-0.567 0.685,-0.522C0.708,-0.476 0.72,-0.421 0.72,-0.354C0.72,-0.292 0.708,-0.239 0.685,-0.194C0.662,-0.149 0.631,-0.113 0.594,-0.084C0.557,-0.055 0.515,-0.034 0.468,-0.021C0.421,-0.007 0.375,0 0.329,0L0.078,0L0.078,-0.708ZM0.309,-0.11C0.342,-0.11 0.374,-0.114 0.407,-0.122C0.44,-0.129 0.469,-0.142 0.496,-0.161C0.522,-0.179 0.543,-0.204 0.56,-0.235C0.577,-0.266 0.585,-0.306 0.585,-0.354C0.585,-0.405 0.577,-0.446 0.56,-0.478C0.543,-0.51 0.522,-0.535 0.496,-0.553C0.469,-0.571 0.44,-0.583 0.407,-0.59C0.374,-0.597 0.342,-0.6 0.309,-0.6L0.204,-0.6L0.204,-0.11L0.309,-0.11Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(323.307 310.034) scale(7.45348)"
      />
      <path
        d="M0.3,-0.708L0.409,-0.708L0.714,0L0.57,0L0.504,-0.162L0.197,-0.162L0.133,0L-0.008,0L0.3,-0.708ZM0.46,-0.27L0.351,-0.558L0.24,-0.27L0.46,-0.27Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(330.313 310.034) scale(7.45348)"
      />
      <path
        d="M0.078,-0.708L0.309,-0.708C0.35,-0.708 0.386,-0.704 0.419,-0.697C0.452,-0.69 0.48,-0.678 0.503,-0.661C0.526,-0.644 0.544,-0.623 0.557,-0.597C0.57,-0.571 0.576,-0.54 0.576,-0.503C0.576,-0.464 0.569,-0.431 0.554,-0.404C0.539,-0.377 0.52,-0.356 0.495,-0.34C0.47,-0.323 0.44,-0.311 0.407,-0.304C0.374,-0.297 0.338,-0.293 0.301,-0.293L0.204,-0.293L0.204,0L0.078,0L0.078,-0.708ZM0.292,-0.399C0.313,-0.399 0.332,-0.401 0.351,-0.404C0.37,-0.407 0.386,-0.412 0.401,-0.42C0.416,-0.427 0.427,-0.438 0.436,-0.451C0.445,-0.464 0.449,-0.482 0.449,-0.503C0.449,-0.524 0.445,-0.541 0.436,-0.554C0.427,-0.567 0.416,-0.577 0.402,-0.584C0.387,-0.591 0.371,-0.596 0.353,-0.599C0.334,-0.601 0.315,-0.602 0.296,-0.602L0.204,-0.602L0.204,-0.399L0.292,-0.399Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(337.067 310.034) scale(7.45348)"
      />
      <path
        d="M0.221,-0.598L0.004,-0.598L0.004,-0.708L0.564,-0.708L0.564,-0.598L0.347,-0.598L0.347,0L0.221,0L0.221,-0.598Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(343.103 310.034) scale(7.45348)"
      />
      <rect
        x="349.409"
        y="304.757"
        width="0.939"
        height="5.277"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        d="M0.078,-0.708L0.204,-0.708L0.204,-0.112L0.505,-0.112L0.505,0L0.078,0L0.078,-0.708Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(352.419 310.034) scale(7.45348)"
      />
      <path
        d="M0.078,-0.708L0.547,-0.708L0.547,-0.598L0.204,-0.598L0.204,-0.418L0.529,-0.418L0.529,-0.312L0.204,-0.312L0.204,-0.112L0.565,-0.112L0.565,0L0.078,0L0.078,-0.708Z"
        fill="#fff"
        fillRule="nonzero"
        transform="translate(357.719 310.034) scale(7.45348)"
      />
      <path
        d="M759.82,489.277C772.861,488.706 783.236,491.318 783.489,513.021C783.856,544.634 784.997,612.912 785.071,641.574C785.08,645.144 784.464,650.356 778.561,650.403C773.373,650.444 764.086,650.465 758.953,650.427C756.312,650.407 750.636,649.936 750.89,641.478C751.325,626.977 751.685,608.238 751.992,587.038C752.285,566.818 752.377,540.946 751.851,533.785C751.703,531.762 751.19,531.345 749.878,531.551C745.31,532.267 720.914,571.222 704.319,605.569C703.432,607.405 713.015,601.495 723.393,596.952C735.984,591.44 749.771,587.251 747.776,592.545C746.439,596.092 726.301,607.237 711.416,619.757C698.686,630.465 690.965,642.817 689.181,645.192C687.194,647.837 685.331,650.304 682.495,650.48C677.913,650.763 669.778,650.555 664.976,650.48C662.299,650.437 659.822,648.194 658.64,645.048C657.032,640.769 659.753,632.185 664.674,619.757C670.104,606.046 678.463,588.759 689.103,570.418C703.051,546.376 722.569,518.289 729.972,509.653C735.031,503.751 738.232,500.269 743.745,496.221C749.95,491.664 757.154,489.394 759.82,489.277Z"
        fill="#fff"
        fillRule="nonzero"
        transform="matrix(.07795 0 0 .07795 248.696 261.851)"
      />
    </g>
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string,
  viewBox: PropTypes.object,
}

export default SVG
